import { User, useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";

type Web3 = {
  isWeb3Connected: boolean;
  address: `0x${string}`;
  user: null;
};

type Web2 = {
  isWeb3Connected: boolean;
  address: null;
  user: User;
};

type Pending = {
  isWeb3Connected: boolean;
  address: null;
  user: null;
};

type ReturnType = { isAuthenticated: boolean | null | undefined; loading: boolean } & (
  | Web3
  | Web2
  | Pending
);

const useMixAuthenticated = (): ReturnType => {
  const { isAuthenticated: isAuth0Authenticated, user, isLoading: isAuth0Loading } = useAuth0();
  const { isConnected: isWeb3Connected, address, isConnecting: isWagmiLoading } = useAccount();

  const [state, setState] = useState<ReturnType>({
    isWeb3Connected: isWeb3Connected,
    loading: isAuth0Loading || isWagmiLoading,
    isAuthenticated: isAuth0Authenticated || isWeb3Connected,
    address: null,
    user: null,
  });

  useEffect(() => {
    if (!isAuth0Loading && !isWagmiLoading) {
      if (isAuth0Authenticated) {
        setState(prev => {
          return {
            ...prev,
            isWeb3Connected: isWeb3Connected,
            loading: isAuth0Loading || isWagmiLoading,
            isAuthenticated: isAuth0Authenticated || isWeb3Connected,
            user: user!,
            address: null,
          };
        });
      } else if (isWeb3Connected) {
        setState(prev => {
          return {
            ...prev,
            isWeb3Connected: isWeb3Connected,
            loading: isAuth0Loading || isWagmiLoading,
            isAuthenticated: isAuth0Authenticated || isWeb3Connected,
            address: address!,
            user: null,
          };
        });
      } else {
        setState({
          isWeb3Connected: isWeb3Connected,
          loading: isAuth0Loading || isWagmiLoading,
          isAuthenticated: isAuth0Authenticated || isWeb3Connected,
          address: null,
          user: null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth0Loading, isWagmiLoading, isWeb3Connected, isAuth0Authenticated, isWagmiLoading]);

  return state;
};

export default useMixAuthenticated;
