/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

type SendEmailResult = {
  success: boolean;
};

interface Auth0SendEmailToParams {
  symbolKeys: string | string[];
  email: string;
  token: string;
}

interface MetamaskSendEmailToParams {
  symbolKeys: string | string[];
  email: string;
  address: `0x${string}`;
}

const auth0sendEmailTo = async ({
  symbolKeys,
  email,
  token,
}: Auth0SendEmailToParams): Promise<SendEmailResult> => {
  const payload: any = { email, symbolKeys };

  const res = await axios.post("/api/user/send-email-stripe", payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const metamaskSendEmailTo = async ({
  symbolKeys,
  email,
  address,
}: MetamaskSendEmailToParams): Promise<SendEmailResult> => {
  const payload: any = { email, address, symbolKeys };

  const res = await axios.post("/api/user/email", payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};

const sendEmail = async ({
  address,
  token,
  symbolKeys,
  email,
}: {
  token?: string;
  address?: string;
  email: string;
  symbolKeys: string[];
}): Promise<SendEmailResult> => {
  if (!token && !address) {
    console.error("No token or address provided");
    return {
      success: false,
    };
  }

  if (token) {
    return auth0sendEmailTo({ symbolKeys, email, token });
  }

  if (address) {
    return metamaskSendEmailTo({ symbolKeys, email, address: address as `0x${string}` });
  }

  return {
    success: false,
  }; // Fallback return type in case none of the conditions match
};

export default sendEmail;
