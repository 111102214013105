import { ButtonHTMLAttributes, FunctionComponent } from "react";

import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: "small" | "medium" | "large";
  variant: "contained" | "outlined";
  icon?: React.ReactNode;
}

const AppButton: FunctionComponent<ButtonProps> = ({
  variant,
  size,
  className,
  children,
  icon,
  ...rest
}) => {
  const baseButtonClasses = clsMerge(
    "flex items-center",
    "text-center text-white",
    EBGaramond.className,
  );
  const containedButtonClasses =
    "bg-titanic-gold hover:bg-titanic-gold-400 box-border ring-titanic-gold ring-1";
  const outlinedButtonClasses = "ring-white ring-1 text-white box-border";

  const sizeClasses = {
    small: "h-[48px] text-sm font-medium ",
    medium:
      "h-[48px] px-4 min-w-[93px] text-base font-semibold leading-normal [letter-spacing:2.7px]",
    large: "h-[56px] px-6 min-w-[182px] leading-[27px] tracking-[2.70px] text-lg font-semibold",
  };

  const disabled =
    "disabled:ring-none disabled-ring-[#B4B4B4] disabled:border-none disabled:text-white disabled:bg-[#B4B4B4] disabled:hover-[#B4B4B4]";

  return (
    <button
      type="button"
      {...rest}
      className={clsMerge(
        baseButtonClasses,
        sizeClasses[size],
        variant === "contained" && containedButtonClasses,
        variant === "outlined" && outlinedButtonClasses,
        rest.disabled && disabled,
        className,
      )}
    >
      {icon && <div className="flex p-2">{icon}</div>}
      <div className="flex flex-1 justify-center text-center">{children}</div>
    </button>
  );
};

export default AppButton;
