import { useCloseDrawer } from "@/hooks/useDrawer";
import { useOpenModal } from "@/hooks/useModal";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";
import { ArrowRight } from "@/utils/icon";

interface Props {
  customClasses?: string;
}

const EarlyAccess = ({ customClasses }: Props) => {
  const openModal = useOpenModal();
  const closeDrawer = useCloseDrawer();

  return (
    <button
      className={clsMerge(
        EBGaramond.className,
        customClasses,
        "flex items-center justify-center",
        "h-[48px] w-[155px] bg-gold hover:bg-gold-400 lg:w-[209px] xl:h-[60px]",
        "text-[22px] leading-[29px] text-white",
        "outline outline-[1px] outline-offset-2 outline-gold hover:outline-gold-400",
        customClasses,
      )}
      onClick={() => {
        openModal("INVITATION_FORM", {
          withModalBackdrop: true,
        });
        closeDrawer();
      }}
    >
      <ArrowRight className="h-[43px] w-[22px] stroke-white stroke-[1px]" />
      Early Access
    </button>
  );
};

export default EarlyAccess;
