/* eslint-disable @typescript-eslint/no-explicit-any */
import { BASE_EXTERNAL_PATH } from "@/utils/api/base";

export interface ConfigurationParameters {
  apiKey?:
    | string
    | Promise<string>
    | ((name: string) => string)
    | ((name: string) => Promise<string>);
  username?: string;
  password?: string;
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath?: string;
  baseOptions?: any;
}

export class Configuration {
  apiKey?:
    | string
    | Promise<string>
    | ((name: string) => string)
    | ((name: string) => Promise<string>);
  username?: string;
  password?: string;
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath: string;
  baseOptions?: any;

  constructor(param: ConfigurationParameters = {}) {
    this.apiKey = param.apiKey;
    this.username = param.username;
    this.password = param.password;
    this.accessToken = param.accessToken;
    this.basePath = param.basePath ?? process.env.NEXT_PUBLIC_APP_EXTERNAL_PATH!;
    this.baseOptions = param.baseOptions;
  }
}

export const getConfiguration = (basePath?: string, accessToken?: any) => {
  return new Configuration({
    apiKey: "",
    accessToken: accessToken,
    basePath: basePath ?? BASE_EXTERNAL_PATH,
  });
};
