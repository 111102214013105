/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import React from "react";

import { useCloseModal, useSetModalView } from "@/hooks/useModal";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";
import { ArrowRight, CancelIcon } from "@/utils/icon";

export interface ScreenCommonProps {
  currentStep: number;
  helpers: any;
}

const PhNargeolet: React.FunctionComponent = () => {
  const closeModal = useCloseModal();
  const setModalView = useSetModalView();

  return (
    <div className="z-50 mx-4 flex min-h-full items-center justify-center text-center sm:items-center sm:p-0 md:mx-0">
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="scrollbar-hide relative z-50 flex h-[80%] max-h-[520px] transform justify-start overflow-hidden overflow-y-scroll rounded-lg border-[2px] border-gold bg-white p-6 text-left shadow-xl transition-all sm:max-h-[600px] sm:w-full sm:max-w-sm md:h-auto md:min-h-[562px] md:max-w-[600px] md:p-[52px]">
          <div className="scrollbar-hide flex flex-col">
            <div className="scrollbar-hide mt-3 max-h-[533px] min-h-[333px] overflow-y-scroll text-left sm:mt-5 md:max-h-[433px] lg:max-h-[333px]">
              <Dialog.Title
                as="h3"
                className={clsMerge(
                  "max-w-[520px] text-left font-eb-garamond text-[28px] font-[600] leading-[37px] text-black",
                )}
              >
                In loving memory of PH Nargeolet
              </Dialog.Title>
              <div className="mt-[28px] font-inter text-sm font-normal leading-[22.4px] text-[#121111]">
                <div>
                  Paul-Henri “PH” Nargeolet, is a true explorer of our time. Through his time with
                  RMS Titanic Inc., he's led eight expeditions, a number of research efforts, and
                  the recovery of more than 5,000 artifacts from the wreck site to help the world
                  understand the TITANIC and her stories.
                </div>
                <div className="mt-6">
                  To celebrate and honor PH's contribution to education, research, and exploration
                  related to TITANIC and the deep water, a portion of the proceeds from TITANIC's
                  Web3 journey will be dedicated to support the ongoing scientific research,
                  historical verification, and public awareness work PH has dedicated his career
                  towards.
                </div>{" "}
                <div className="mt-6">RMS Titanic, Inc., and Artifact Labs</div>
              </div>
            </div>
            <button
              className={clsMerge(
                "flex h-[60px] w-full min-w-[237px] items-center justify-center bg-gold px-[25px] py-[12.5px] text-[19px] font-bold text-white shadow-sm hover:bg-gold-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold lg:w-auto",
                "text-[22px] outline outline-[1px] outline-offset-2 outline-[#A38021] lg:ml-2",
                "mt-[55px] self-center",
                EBGaramond.className,
              )}
              onClick={() =>
                setModalView("INVITATION_FORM", {
                  withModalBackdrop: true,
                })
              }
            >
              <ArrowRight className="mr-3 h-7 w-7 stroke-white stroke-[1px]" />
              Stay Up-To-Date
            </button>
          </div>
          <button
            type="reset"
            onClick={() => {
              closeModal();
            }}
          >
            <CancelIcon className="absolute right-0 top-0 z-50 mx-3 my-3 h-6 w-6 text-grey-800 hover:text-grey-600" />
          </button>
        </Dialog.Panel>
      </Transition.Child>{" "}
    </div>
  );
};

export default PhNargeolet;
