import ArtifactLabsLogo from "@public/artifactlabs_logo_4x.png";
import TitanicLogo from "@public/rms_titanic_logo_2x.png";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import Container from "@/components/Layout/Container";
import { clsMerge } from "@/utils/cls-merge";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "@/utils/icon";

const social = [
  {
    url: "https://www.facebook.com/rmstitanicinc/",
    icon: <FacebookIcon className="h-[32px] w-[32px] " />,
  },
  {
    url: "https://twitter.com/rmstitanic_inc?lang=en",
    icon: <TwitterIcon className="h-[32px] w-[32px] " />,
  },
  {
    url: "https://www.instagram.com/rmstitanicinc",
    icon: <InstagramIcon className="h-[32px] w-[32px]" />,
  },
];

const Footer: React.FunctionComponent = () => {
  return (
    <Container
      className={clsMerge(
        "flex items-center justify-center pb-16 text-[14px] font-[300] leading-[20px] text-white",
        "bg-black text-center",
      )}
    >
      <div className="mx-auto">
        <div className="mt-[60px] flex h-full flex-col items-center justify-center space-y-[26px] md:mt-[95px] md:flex-row md:items-start md:space-x-[108px] md:space-y-0 lg:space-x-[48px]">
          <div className="inline-flex space-x-10">
            <Link
              className="flex items-center justify-start"
              href="https://www.discovertitanic.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                alt="titanic logo"
                className="h-[72px] min-h-[72px] w-[162px] min-w-[162px] md:h-[72px] md:min-h-[72px] md:w-[162px] md:min-w-[162px] xl:h-[72px] xl:min-h-[72px] xl:w-[162px] xl:min-w-[162px]"
                src={TitanicLogo}
              />
            </Link>
            <Link
              className="flex items-center justify-start"
              href="https://www.artifactlabs.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                alt="artifactlabs logo"
                className="h-[68px] min-h-[68px] w-[64px] min-w-[64px]  xl:h-[72px] xl:min-h-[72px] xl:w-[72px] xl:min-w-[72px]"
                src={ArtifactLabsLogo}
              />
            </Link>
          </div>
        </div>
        <div className="mt-10 flex items-center justify-center space-x-[30px]">
          {social.map((each, index) => (
            <Link
              href={each.url}
              key={`footer.social.${index}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {each.icon}
            </Link>
          ))}
        </div>
        <div className="mt-10">
          <div className="theme-small-text1 mb-8">
            RMS Titanic, Inc. and Artifact Labs are excited to collaborate on TITANIC&apos;s Digital
            Journey.
          </div>
          <time className="theme-small-text1">
            © {new Date().getFullYear()} RMS Titanic, Inc., Artifact Labs
          </time>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
