import dynamic from "next/dynamic";

export const DynamicConnectButton = dynamic(
  () => import("@/components/Button/ConnectButton").then(({ ConnectButton }) => ConnectButton),
  {
    ssr: false,
  },
);

export const DynamicBoardNowButton = dynamic(
  () => import("@/components/Button/BoardNowButton").then(({ BoardNowButton }) => BoardNowButton),
  {
    ssr: false,
  },
);
