import React from "react";

import { clsMerge } from "@/utils/cls-merge";

interface Props {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
  el?: HTMLElement;
  isHeader?: boolean;
  withFullWidth?: boolean;
}

const Container: React.FC<Props> = ({ children, className, el = "div" }) => {
  const rootClassName = clsMerge("px-[15px] md:px-10 max-w-supported mx-auto", className);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> = el as any;

  return <Component className={rootClassName}>{children}</Component>;
};

export default Container;
