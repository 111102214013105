import { useAuth0 } from "@auth0/auth0-react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useConnect } from "wagmi";

import { AppButton } from "@/components/Button";
import MetamaskIcon from "@/components/Icons/MetamaskIcon";
import { useCloseModal } from "@/hooks/useModal";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond, inter } from "@/utils/font";
import { gtmCustomEvent } from "@/utils/gtm";

const MetamaskConnectorId = "io.metamask";
const MetamaskMobileConnectorId = "io.metamask.mobile";

const LoginModal: React.FunctionComponent = () => {
  const [ready, setReady] = useState(false);
  const closeModal = useCloseModal();
  const { loginWithRedirect } = useAuth0();
  const { connectors, connectAsync } = useConnect();

  useEffect(() => {
    (async () => {
      try {
        const metamaskConnector = connectors.find(
          connector =>
            connector?.id === MetamaskConnectorId || connector?.id === MetamaskMobileConnectorId,
        );

        !!metamaskConnector && (await metamaskConnector.getProvider());
      } catch (error) {
        console.error(error);
      } finally {
        setReady(true);
      }
    })();
  }, [connectors]);

  return (
    <div className="z-50 mx-0 flex min-h-full w-screen min-w-full items-center justify-center text-center backdrop-blur-sm md:mx-4">
      <div className="max-w-[320px] bg-white px-6 pb-10 pt-6">
        <div className="scrollbar-hide flex flex-col">
          <div className="flex flex-1 justify-end">
            <button className="w-min" onClick={() => closeModal()}>
              <XMarkIcon className="h-6 w-6 text-titanic-black" />
            </button>
          </div>
          <div className="scrollbar-hide mt-4 overflow-y-scroll text-left sm:mt-4">
            <h3 className={clsMerge("theme-h3 text-center", EBGaramond.className)}>LOGIN</h3>
            <div className={clsMerge("theme-body2 mt-10 font-normal", inter.className)}>
              Create a wallet with your email address or connect to your own wallet.
            </div>
          </div>

          <div className="mt-4 flex flex-col items-stretch gap-4">
            <AppButton
              className={clsMerge(
                EBGaramond.className,
                "text-xl font-semibold leading-normal tracking-[2.7px] text-titanic-gold ring-1 ring-titanic-gold",
              )}
              icon={
                <svg
                  className="h-6 w-6 stroke-titanic-gold"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              size="medium"
              variant="outlined"
              onClick={() => {
                closeModal();
                loginWithRedirect({ appState: { returnTo: window.location.pathname } });
              }}
            >
              EMAIL
            </AppButton>

            <AppButton
              className={clsMerge(
                EBGaramond.className,
                "text-xl font-semibold leading-normal tracking-[2.7px] text-titanic-gold ring-1 ring-titanic-gold",
              )}
              disabled={!ready}
              icon={<MetamaskIcon className="h-6 w-6" />}
              size="medium"
              variant="outlined"
              onClick={async () => {
                const metamaskConnector = connectors.find(
                  connector =>
                    connector?.id === MetamaskConnectorId ||
                    connector?.id === MetamaskMobileConnectorId,
                );

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any)?.ethereum) {
                  if (metamaskConnector) {
                    const data = await connectAsync({ connector: metamaskConnector });
                    if (data?.accounts) {
                      gtmCustomEvent({
                        action: "login_success_metamask",
                      });
                    }
                  }
                } else {
                  window.open(
                    `https://metamask.app.link/dapp/${window.location.origin}`,
                    "_blank",
                    "noreferrer,noopener",
                  );
                }
                closeModal();
              }}
            >
              METAMASK
            </AppButton>
          </div>
          <div className="theme-small-text1 mt-6 text-left">
            By using our services, you agree to our{" "}
            <Link
              className="text-titanic-blue underline underline-offset-2"
              href="/Terms and Conditions - TITANIC's Digital Journey.pdf"
            >
              Terms and Conditions
            </Link>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
