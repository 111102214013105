/* eslint-disable react/no-unescaped-entities */
import { Transition } from "@headlessui/react";
import { FunctionComponent } from "react";
import React from "react";

import StripeEmbeddedCheckout from "@/components/Checkout/stripe/StripeEmbeddedCheckout";
import { clsMerge } from "@/utils/cls-merge";

const CheckOutModalView: FunctionComponent<{
  customName?: string;
  symbolKeys: string[];
}> = ({ customName, symbolKeys }) => {
  return (
    <div className="mx-4 flex h-[70%] items-center justify-center overflow-y-scroll text-center sm:items-center sm:p-0 md:mx-0">
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className={clsMerge(
            "flex h-full w-full flex-col rounded-lg bg-white px-4 pb-6 pt-10 antialiased md:w-[456px]",
          )}
        >
          <StripeEmbeddedCheckout customName={customName} symbolKeys={symbolKeys} />
        </div>
      </Transition.Child>
    </div>
  );
};

export default CheckOutModalView;
