import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import UIAtom from "@/recoil/UI";
import { State } from "@/recoil/UI/atom";

export type MODAL_VIEWS =
  | "INVITATION_FORM"
  | "LOGIN"
  | "CLAIM_SUCCESS"
  | "CLAIM_FAILED"
  | "CHECKOUT_STRIPE"
  | "TRANSACTION_IN_PROGRESS"
  | "STRIPE_TRANSACTION_IN_PROGRESS"
  | "PH_NARGEOLET";

export const useOpenModal = () => {
  const setUI = useSetRecoilState(UIAtom);
  return useCallback(
    (view: MODAL_VIEWS = "INVITATION_FORM", options?: Partial<State>) =>
      setUI(state => ({
        ...state,
        modalView: view,
        displayModal: true,
        ...options,
      })),
    [setUI],
  );
};

export const useCloseModal = () => {
  const setUI = useSetRecoilState(UIAtom);
  return () =>
    setUI(state => ({
      ...state,
      modalProps: {},
      displayModal: false,
    }));
};

export const useSetModalView = () => {
  const setUI = useSetRecoilState(UIAtom);
  return (view: MODAL_VIEWS, options?: Partial<State>) =>
    setUI(state => ({
      ...state,
      modalView: view,
      displayModal: true,
      ...options,
    }));
};
