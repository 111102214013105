import Link, { LinkProps } from "next/link";
import { HTMLAttributes, forwardRef } from "react";
import { UrlObject } from "url";

import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

interface AppLinkProps extends LinkProps, HTMLAttributes<HTMLAnchorElement> {
  href: string | UrlObject;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppLink = forwardRef(({ href, children, className, ...rest }: AppLinkProps, ref: any) => {
  return (
    <Link
      className={clsMerge(
        "relative animate-fade-in text-[18px] font-[600] uppercase text-white",
        "leading-normal [letter-spacing:2.7px] hover:underline hover:underline-offset-8",
        EBGaramond.className,
        className,
      )}
      href={href}
      ref={ref}
      {...rest}
    >
      {children}
    </Link>
  );
});

AppLink.displayName = "AppLink";

export type { AppLinkProps };
export default AppLink;
