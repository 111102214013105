import globalAxios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { Configuration } from "@/utils/api/configuration";

export const BASE_EXTERNAL_PATH = process.env.NEXT_PUBLIC_APP_EXTERNAL_PATH! || "";

export interface RequestArgs {
  url: string;
  options: AxiosRequestConfig;
}

export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath?: string | "",
    protected axios: AxiosInstance = globalAxios,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

export class RequiredError extends Error {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  name: "RequiredError" = "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
