/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { ethers } from "ethers";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import {
  useAccount,
  useReadContract,
  useSwitchChain,
  useConnections,
  useReadContracts,
} from "wagmi";
import zod from "zod";

import { AppButton } from "@/components/Button";
import { useCloseModal } from "@/hooks/useModal";
import { clsMerge } from "@/utils/cls-merge";
import ERC20Abi from "@/utils/evm/ERC20.abi";
import TitanicTokenAbi from "@/utils/evm/TitanicToken.abi";
import sendEmail from "@/utils/fetcher/send-email";
import { EBGaramond, inter } from "@/utils/font";
import { gtmCustomEvent } from "@/utils/gtm";

declare global {
  interface Window {
    ethereum: any;
  }
}
enum ChainId {
  Polygon = 137,
  Amoy = 80002,
  Local = 1337,
}

enum ModalStatus {
  Error,
  Initializing,
  PaymentProcessed,
  Email,
}

const getMintRecord = async (tx: string) => {
  const res = await axios.get("/api/assets/mint-tx", {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      tx,
    },
  });
  return res.data;
};

const PUBLIC_ACCESS_ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_PUBLIC_ACCESS_ALCHEMY_API_KEY;
const NETWORK = process.env.NEXT_PUBLIC_ETHER_JS_NETWORK;

const USDC_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS_USDC! as `0x${string}`;
const CHAI_ID = process.env.NEXT_PUBLIC_APP_ENV === "production" ? ChainId.Polygon : ChainId.Amoy;

const TransactionInProgress: React.FunctionComponent<{
  customName: string;
  symbolKeys: string[];
  targetTransactionContractAddress: `0x${string}`;
}> = ({ customName, symbolKeys, targetTransactionContractAddress }) => {
  const closeModal = useCloseModal();
  const router = useRouter();
  const [dbTxId, setDbTxId] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [status, setStatus] = useState(ModalStatus.Initializing);
  const [pullingIntervalId, setPullingIntervalId] = useState<NodeJS.Timeout | undefined>();
  const [errorRef, setErrorRef] = useState("");
  const [email, setEmail] = useState("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  const { address } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const [connection] = useConnections();
  const currentChainId = connection?.chainId;

  const {
    data: usdcData,
    refetch,
    isLoading: usdcContractIsLoading,
  } = useReadContracts({
    contracts: [
      {
        address: USDC_CONTRACT_ADDRESS,
        abi: ERC20Abi,
        functionName: "balanceOf",
        args: [address!],
        chainId: CHAI_ID,
      },
      {
        address: USDC_CONTRACT_ADDRESS,
        abi: ERC20Abi,
        functionName: "allowance",
        args: [address!, targetTransactionContractAddress],
        chainId: CHAI_ID,
      },
    ],
    query: {
      enabled: CHAI_ID === currentChainId,
    },
  });

  const { data: tokenPrice, isLoading: tokenContractIsLoading } = useReadContract({
    address: targetTransactionContractAddress,
    abi: TitanicTokenAbi,
    functionName: "priceUSDC",
    chainId: CHAI_ID,
    query: {
      enabled: CHAI_ID === currentChainId,
    },
  });

  // hardcode ErrorRef: CKF651627230
  const handlePurchase = async () => {
    if (pullingIntervalId) return;

    const chainId = CHAI_ID;

    if (!address || !tokenPrice || !chainId) {
      console.error("User address, token price, or chain ID is missing.");
      return;
    }
    setErrorRef(code => `${code}C`);

    if (!usdcData || usdcData.length < 2) {
      console.error("USDC data is incomplete.");
      return;
    }

    setErrorRef(code => `${code}K`);

    try {
      if (typeof usdcData?.[0]?.result !== "undefined") {
        if (usdcData[0].result < tokenPrice) {
          setStatus(ModalStatus.Error);
          return;
        }
      }

      setErrorRef(code => `${code}F`);
      if (typeof window.ethereum !== "undefined") {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const alchemyProvider = new ethers.AlchemyProvider(NETWORK, PUBLIC_ACCESS_ALCHEMY_API_KEY);
        const network = await provider.getNetwork();
        if (network.chainId !== BigInt(chainId)) {
          throw new Error(
            `Incorrect network: Please switch to the network with chainId ${chainId}`,
          );
        }

        const signer = await provider.getSigner();

        setErrorRef(code => `${code}6`);
        if (typeof usdcData[1]?.result !== "undefined" && usdcData[1].result < tokenPrice) {
          const setAllowanceContract = new ethers.Contract(USDC_CONTRACT_ADDRESS, ERC20Abi, signer);
          setErrorRef(code => `${code}5`);
          const allowanceTx = await setAllowanceContract["approve"](
            targetTransactionContractAddress,
            tokenPrice,
          );
          const allowanceTxHash = allowanceTx?.hash;
          setErrorRef(code => `${code}1`);
          await alchemyProvider.waitForTransaction(allowanceTxHash);
          setErrorRef(code => `${code}6`);
        }

        // Pay with USDC
        setErrorRef(code => `${code}2`);
        const tokenContract = new ethers.Contract(
          targetTransactionContractAddress,
          TitanicTokenAbi,
          signer,
        );
        const payUSDCTx = await tokenContract["payUSDC"](customName);
        const payUSDCTxHash = payUSDCTx?.hash;
        setErrorRef(code => `${code}7`);
        await alchemyProvider.waitForTransaction(payUSDCTxHash);
        setStatus(ModalStatus.PaymentProcessed);
        setErrorRef(code => `${code}2`);

        const checkNftDetailsAndUpdateStatus = async () => {
          const { dbTxId } = await getMintRecord(payUSDCTxHash);
          if (dbTxId) {
            setDbTxId(dbTxId);
            setStatus(ModalStatus.Email);
            clearInterval(intervalId);
          }
        };

        const intervalId = setInterval(checkNftDetailsAndUpdateStatus, 3000);
        setPullingIntervalId(intervalId);
        setErrorRef(code => `${code}0`);
      }

      // If allowance is insufficient, set new allowance
    } catch (error) {
      console.error(error);
      setStatus(ModalStatus.Error);
    }
  };

  useEffect(() => {
    if (pullingIntervalId) return () => clearInterval(pullingIntervalId);
  }, [pullingIntervalId]);

  useEffect(() => {
    if (currentChainId !== CHAI_ID) {
      switchChainAsync({ chainId: CHAI_ID });
    } else if (!tokenContractIsLoading && !usdcContractIsLoading) {
      handlePurchase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    tokenPrice,
    currentChainId,
    tokenContractIsLoading,
    usdcContractIsLoading,
    switchChainAsync,
  ]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      const currentPath = router.asPath;
      if (as !== currentPath) {
        if (confirm("Are you sure?")) {
          closeModal();
          return false;
        } else {
          window.history.pushState(null, "", currentPath);
          return false;
        }
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <div
      className={clsMerge(
        EBGaramond.className,
        "relative mx-auto h-full w-screen overflow-y-scroll",
      )}
    >
      <div
        className={clsMerge(
          "mx-auto mt-[30%] h-[500px] w-full md:mt-[10%]",
          (status === ModalStatus.Initializing || status === ModalStatus.Error) &&
            "mt-0 flex h-screen items-center justify-center md:mt-0", // use flex to center the content without timeline
        )}
      >
        {status > ModalStatus.Initializing && (
          <div
            className={clsMerge(
              "flex items-center justify-center gap-6 px-6",
              status === ModalStatus.PaymentProcessed && "pb-40",
              status === ModalStatus.Email && "pb-20",
            )}
          >
            <div className="inline-flex w-fit flex-col items-center justify-center gap-4 rounded-[100px]">
              <div className="inline-flex items-center justify-center gap-4">
                <div
                  className={clsMerge(
                    "flex h-[32px] w-[32px] items-center justify-center rounded-[100px]",
                    status >= ModalStatus.PaymentProcessed && "bg-titanic-blue",
                    status < ModalStatus.PaymentProcessed && "ring-2 ring-inset ring-zinc-500",
                  )}
                >
                  <svg
                    className={clsMerge(
                      "h-5 w-5 stroke-white stroke-[3px]",
                      status < ModalStatus.PaymentProcessed && "hidden",
                    )}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="theme-small-text1 w-max text-center text-white">
                Payment processed
              </div>
            </div>
            <div className="mb-7 h-0.5 w-[120px] bg-titanic-blue" />
            <div className="inline-flex w-fit flex-col items-center justify-center gap-4 rounded-[100px]">
              <div className="inline-flex items-center justify-center gap-4">
                <div
                  className={clsMerge(
                    "flex h-[32px] w-[32px] items-center justify-center rounded-[100px]",
                    status >= ModalStatus.Email && "bg-titanic-blue",
                    status < ModalStatus.Email && "ring-2 ring-inset ring-zinc-500",
                  )}
                >
                  <svg
                    className={clsMerge(
                      "h-6 w-6 stroke-white stroke-[3px]",
                      status < ModalStatus.Email && "hidden",
                    )}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="theme-small-text1 w-max text-center text-white">
                {status < ModalStatus.Email ? "Delivering item" : "Item delivered"}
              </div>
            </div>
          </div>
        )}

        {status === ModalStatus.Error && (
          <section
            className={clsMerge("max-auto mx-auto leading-normal text-white lg:max-w-[588px]")}
          >
            <div className="flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Purchase Unsuccessful
            </div>
            <p
              className={clsMerge(
                inter.className,
                "theme-body2 md:theme-body1 mx-auto mt-6 px-4 font-normal",
              )}
            >
              We are unable to process your purchase, please make sure there is sufficient funds
              (USDC) in your wallet and try again. If you are still having troubles, please contact{" "}
              <Link
                className="text-titanic-blue underline underline-offset-2 outline-none ring-0 focus:outline-none"
                href="https://support.metamask.io/hc/en-us"
                rel="noopener noreferrer"
                target="_blank"
              >
                MetaMask
              </Link>{" "}
              for further assistance. {errorRef}
            </p>

            <div className="mt-8 flex w-full flex-1 justify-center">
              <AppButton
                size="large"
                variant="contained"
                onClick={async () => {
                  await refetch();
                  setStatus(ModalStatus.Initializing);
                  closeModal();
                }}
              >
                Try Again
              </AppButton>
            </div>
          </section>
        )}

        {status === ModalStatus.Initializing && (
          <section
            className={clsMerge("mx-auto w-full leading-normal text-white lg:max-w-[588px]")}
          >
            <div className="flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Purchase in progress
            </div>
            <p
              className={clsMerge(
                inter.className,
                "theme-body2 md:theme-body1 mx-auto mt-6 px-4 font-normal",
              )}
            >
              Please complete your transaction in the pop-up. If you are having troubles, please
              refresh this page or contact{" "}
              <Link
                className="text-titanic-blue underline underline-offset-2 outline-none ring-0 focus:outline-none"
                href="https://support.metamask.io/hc/en-us"
                rel="noopener noreferrer"
                target="_blank"
              >
                MetaMask
              </Link>{" "}
              for further assistance.
            </p>
          </section>
        )}

        {status === ModalStatus.PaymentProcessed && (
          <section className={clsMerge("mx-auto w-full text-white lg:max-w-[588px]")}>
            <div className="flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Purchase in progress
            </div>
            <p className="theme-body2 md:theme-body1 mx-auto mt-6 px-4 text-center text-lg font-normal">
              We{"'"}re delivering your item. Please do not refresh the page or exit out of the
              window during this process.
            </p>
          </section>
        )}

        {status === ModalStatus.Email && (
          <section className={clsMerge("mx-auto w-full text-white lg:max-w-[588px]")}>
            <div className="flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Purchase Completed
            </div>
            <p
              className={clsMerge(
                inter.className,
                "theme-body2 md:theme-body1 mx-auto mt-10 px-4 text-center font-normal",
              )}
            >
              Congratulations! Your item is now available in My Collection. To get your confirmation
              email and stay up-to-date on your purchase, please share your email with us.
            </p>
            <input
              className={clsMerge(
                "mx-4 mt-10 inline-flex h-14 w-full items-center justify-start gap-2 bg-[#191919] px-4 py-2 placeholder:text-[#87888C]",
                "theme-body2 md:theme-body1 z-50 font-normal focus-within:outline-none focus:outline-none",
                inter.className,
              )}
              placeholder="Email"
              ref={emailInputRef}
              type="email"
              onChange={e => {
                setEmailError("");
                setEmail(e.target.value);
              }}
            />
            {!!emailError && (
              <div
                className={clsMerge("mx-4 mt-4 text-start text-sm text-red-400", inter.className)}
              >
                {emailError}
              </div>
            )}
            <div className="mt-[32px] flex w-full flex-1 justify-center">
              <AppButton
                size="large"
                variant="contained"
                onClick={async () => {
                  const schema = zod.string().email();

                  if (!email || email.trim() === "") {
                    setEmailError("Enter your email");
                    if (emailInputRef?.current) emailInputRef.current.focus();
                    return;
                  }

                  if (email) {
                    const parsed = schema.safeParse(email);
                    if (parsed.success) {
                      setEmailError("");
                      await sendEmail({ email, address, symbolKeys });
                      gtmCustomEvent({
                        action: "fill_email_after_payment",
                      });
                      closeModal();
                      if (dbTxId) {
                        router.push(`/my-collection/${dbTxId}`);
                      } else {
                        router.push(`/my-collection`);
                      }
                    } else {
                      setEmailError(
                        "Invalid email address. Please check your input and try again.",
                      );
                      if (emailInputRef?.current) emailInputRef.current.focus();
                    }
                  } else {
                    closeModal();
                    if (dbTxId) {
                      router.push(`/my-collection/${dbTxId}`);
                    } else {
                      router.push(`/my-collection`);
                    }
                  }
                }}
              >
                OK
              </AppButton>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default TransactionInProgress;
