import { useSetRecoilState } from "recoil";

import UIAtom from "@/recoil/UI";

type DRAWER_VIEWS = "HOME_NAVIGATION";

export const useOpenDrawer = () => {
  const setUI = useSetRecoilState(UIAtom);
  return () =>
    setUI(state => ({
      ...state,
      modalProps: {},
      displayDrawer: true,
    }));
};

export const useCloseDrawer = () => {
  const setUI = useSetRecoilState(UIAtom);
  return () =>
    setUI(state => ({
      ...state,
      modalProps: {},
      displayDrawer: false,
    }));
};

export const useSetDrawerView = () => {
  const setUI = useSetRecoilState(UIAtom);
  return (view: DRAWER_VIEWS) =>
    setUI(state => ({
      ...state,
      modalProps: {},
      drawerView: view,
    }));
};
