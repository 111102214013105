import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { getRequestConfig, getUrl } from "../helper";

import { Subscriber } from "@/types/api.types";
import { BASE_EXTERNAL_PATH, RequestArgs } from "@/utils/api/base";
import { Configuration } from "@/utils/api/configuration";

export const ApiAxiosParamCreator = (configuration?: Configuration) => {
  return {
    updateGooglesheet: async (
      path: string,
      body: Subscriber,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const requestOptions = await getRequestConfig("POST", options, configuration, body);

      return {
        url: getUrl(path, /* queryParams */ {}, options, configuration),
        options: requestOptions,
      };
    },
    addEmailToWhitelist: async (
      path: string,
      body: Subscriber,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const requestOptions = await getRequestConfig("POST", options, configuration, body);

      return {
        url: getUrl(path, /* queryParams */ {}, options, configuration),
        options: requestOptions,
      };
    },
    subscribeNewsletter: async (
      path: string,
      body: Subscriber,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const requestOptions = await getRequestConfig("POST", options, configuration, body);

      return {
        // url: getUrl(path, /* queryParams */ {}, options, configuration),
        url: "/api/user/newsletter",
        options: requestOptions,
      };
    },
    createUser: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const requestOptions = await getRequestConfig("POST", options, configuration, {});

      return {
        url: getUrl(path, /* queryParams */ {}, options, configuration),
        options: requestOptions,
      };
    },
    createPaymentSession: async (
      path: string,
      body: {
        walletAddress?: `0x${string}`;
        customName?: string;
        symbolKeys: string;
      },
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const requestOptions = await getRequestConfig("POST", options, configuration, body);

      return {
        url: getUrl(path, /* queryParams */ {}, options, configuration),
        options: requestOptions,
      };
    },
  };
};

/* Functional Interface */
export const ApiFp = (configuration?: Configuration) => {
  return {
    async updateGooglesheet(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
      const axiosArgs = await ApiAxiosParamCreator(configuration).updateGooglesheet(
        path,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_EXTERNAL_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...axiosArgs.options,
          url: basePath + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async addEmailToWhitelist(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
      const axiosArgs = await ApiAxiosParamCreator(configuration).addEmailToWhitelist(
        path,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_EXTERNAL_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...axiosArgs.options,
          url: basePath + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async subscribeNewsletter(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
      const axiosArgs = await ApiAxiosParamCreator(configuration).subscribeNewsletter(
        path,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_EXTERNAL_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...axiosArgs.options,
          url: basePath + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async createUser(path: string, options?: AxiosRequestConfig) {
      const axiosArgs = await ApiAxiosParamCreator(configuration).createUser(path, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_EXTERNAL_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...axiosArgs.options,
          url: basePath + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async createPaymentSession(
      path: string,
      body: {
        walletAddress?: `0x${string}`;
        customName?: string;
        symbolKeys: string;
      },
      options?: AxiosRequestConfig,
    ) {
      const axiosArgs = await ApiAxiosParamCreator(configuration).createPaymentSession(
        path,
        body,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_EXTERNAL_PATH) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...axiosArgs.options,
          url: basePath + axiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/* Factory */
export const ApiFactory = (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) => {
  return {
    async updateGooglesheet(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return ApiFp(configuration)
        .updateGooglesheet(path, body, options)
        .then(request => request(axios, basePath));
    },
    async addEmailToWhitelist(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return ApiFp(configuration)
        .addEmailToWhitelist(path, body, options)
        .then(request => request(axios, basePath));
    },
    async subscribeNewsletter(
      path: string,
      body: Subscriber,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return ApiFp(configuration)
        .subscribeNewsletter(path, body, options)
        .then(request => request(axios, basePath));
    },
    async createUser(
      path: string,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<{ success: boolean }>> {
      return ApiFp(configuration)
        .createUser(path, options)
        .then(request => request(axios, basePath));
    },
    async createPaymentSession(
      path: string,
      body: {
        walletAddress?: `0x${string}`;
        customName?: string;
        symbolKeys: string;
      },
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<{ success: boolean }>> {
      return ApiFp(configuration)
        .createPaymentSession(path, body, options)
        .then(request => request(axios, basePath));
    },
  };
};
